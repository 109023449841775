import { Tooltip } from '@mui/material';
import { CustomRatioImage } from '../../../../../components/image/CustomRatioImage';
import * as St from './weget-login-methods.styled';
import { useUserContextValueHook } from '../../../../../contexts/UserContextProvider';

export function WegetLoginMethods(props) {
    const userContextValueHook = useUserContextValueHook();

    const providerData = userContextValueHook?.user?.providerData;
    
    return (
        <>
            <St.Container>
                <St.ContentBox>
                    <h2 className='title'>연동된 로그인 방식</h2>
                    <div className='iconList'>
                        {providerData?.map(data => {
                            if (data?.providerId === 'password') {
                                return (
                                    <Tooltip title='이메일 로그인' key={data?.providerId}>
                                        <div className='icon'>
                                            <CustomRatioImage>
                                                <CustomRatioImage.Image src='/assets/icons/mail_default_000000.svg' />
                                            </CustomRatioImage>
                                        </div>
                                    </Tooltip>
                                );
                            }

                            if (data?.providerId === 'google.com') {
                                return (
                                    <Tooltip title='구글 로그인' key={data?.providerId}>
                                        <div className='icon'>
                                            <CustomRatioImage>
                                                <CustomRatioImage.Image src='/assets/icons/google_icon.svg' />
                                            </CustomRatioImage>
                                        </div>
                                    </Tooltip>
                                );
                            }
                        })}
                    </div>
                </St.ContentBox>
            </St.Container>
        </>
    );
}