import { UsersApi } from "../../../../apis/UsersApi";
import { useErrorHandler } from "../../../../apis/useErrorHandler";

const usersApi = UsersApi();

export function useApiHook(props) {
    const errorHandler = useErrorHandler();

    const reqSendPhoneVerificationCode = async ({ body, headers }) => {

        return await usersApi.sendPhoneVerificationCodeForFindUser({ body: body, headers: headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
    }

    const reqFindUser = async ({ body, headers }) => {

        return await usersApi.findUser({ body: body, headers: headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
    }

    return {
        reqSendPhoneVerificationCode,
        reqFindUser
    }
}