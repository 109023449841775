import CustomBlockButton from '../../../../../components/button/CustomBlockButton';
import * as St from './weget-phone-setting.styled';
import { customToast } from '../../../../../components/toast/custom-react-toastify/v1';
import { useUserContextValueHook } from '../../../../../contexts/UserContextProvider';
import { CustomBackdropController } from '../../../../../components/backdrop/default/v1/core';
import { useEffect, useState } from 'react';
import { CustomDialog } from '../../../../../components/dialog/v1/CustomDialog';
import { CustomLabelInput } from '../../../../../components/input/CustomLabelInput';
import { CustomRegexUtils } from '../../../../../utils/CustomRegexUtils';
import { useApiHook } from '../../hooks/useApiHook';
import { CircularProgress } from '@mui/material';

function convertPhoneNumberWithDashed(phoneNumber) {
    // 숫자만 남기기 위해 모든 비숫자 제거
    const cleaned = phoneNumber.replace(/\D/g, '');

    if (cleaned.length === 11) {
        // 11자리 전화번호의 경우 xxx-xxxx-xxxx
        return cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    } else if (cleaned.length === 10) {
        // 10자리 전화번호의 경우 xxx-xxx-xxxx
        return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    } else {
        // 전화번호가 10자리나 11자리가 아니면 오류 메시지 반환
        return phoneNumber;
    }
}

export function WegetPhoneSetting(props) {
    const apiHook = useApiHook();

    const [userInfo, setUserInfo] = useState(null);
    const [registrationModalOpen, setRegistrationModalOpen] = useState(false);

    useEffect(() => {
        handleReqFetchUserInfo();
    }, []);

    const handleReqFetchUserInfo = async () => {
        const userInfo = await apiHook.reqFetchUser({ body: null, headers: null });

        if (userInfo?.content) {
            setUserInfo(userInfo?.content);
        }
    }

    const handleSetUserInfo = (newUserInfo) => {
        setUserInfo(newUserInfo);
    }

    const toggleRegistrationModalOpen = (bool) => {
        setRegistrationModalOpen(bool)
    }

    const dashedPhoneNumber = userInfo?.phoneNumber ? convertPhoneNumberWithDashed(userInfo?.phoneNumber) : '';

    return (
        <>
            <St.Container>
                <St.ContentBox>
                    <h2 className='title'>휴대전화 설정</h2>
                    <div className='description'>
                        휴대전화를 등록하시면 아이디, 패스워드 찾기 등 부가 서비스를 편리하게 이용할 수 있습니다.
                    </div>
                    {dashedPhoneNumber &&
                        <div className='phoneNumber'>
                            등록된 번호 : {dashedPhoneNumber}
                        </div>
                    }
                    <div className='sendMailBox'>
                        <CustomBlockButton
                            type='button'
                            onClick={() => toggleRegistrationModalOpen(true)}
                        >
                            등록 및 변경
                        </CustomBlockButton>
                    </div>
                </St.ContentBox>
            </St.Container>

            {registrationModalOpen &&
                <RegistrationModal
                    open={registrationModalOpen}
                    toggleOpenClose={toggleRegistrationModalOpen}

                    userInfo={userInfo}
                    onSetUserInfo={handleSetUserInfo}
                />
            }
        </>
    );
}

function RegistrationModal({
    open,
    toggleOpenClose,
    userInfo,
    onSetUserInfo
}) {
    const apiHook = useApiHook();

    const [mode, setMode] = useState('DEFAULT'); // DEFAULT, VERIFICATION
    const [isLoading, setIsLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerficationCode] = useState('');

    const handleChangePhoneNumber = (e) => {
        let value = e.target.value;

        const regex = /^[0-9]{0,11}$/;

        if (regex.test(value)) {
            setPhoneNumber(value);
        }
    }

    const handleChangeVerificationCode = (e) => {
        let value = e.target.value;

        const regex = /^[0-9]{0,6}$/;

        if (regex.test(value)) {
            setVerficationCode(value);
        }
    }

    const handleSendVerificationCode = async () => {
        if (!CustomRegexUtils().checkPhoneNumberFormat(phoneNumber)) {
            customToast.error('휴대전화번호를 정확히 입력해 주세요.');
            return;
        }
        if (!userInfo) {
            customToast.error('유저 조회에 실패 했습니다. 문제가 지속적으로 발생시 새로고침 후 다시 시도해 주세요.');
            return;
        }

        if(userInfo?.phoneNumber === phoneNumber){
            customToast.warning('현재 등록된 번호와 같습니다.');
            return;
        }

        const body = {
            userId: userInfo?.id,
            phoneNumber: phoneNumber
        }

        setIsLoading(true);
        const result = await apiHook.reqSendPhoneVerificationCode({ body: body });
        setIsLoading(false);

        if (result?.content) {
            setMode('VERIFICATION')
        }
    }

    const handleRegisterPhoneNumber = async () => {
        if (!CustomRegexUtils().checkPhoneNumberFormat(phoneNumber)) {
            customToast.error('휴대전화번호를 정확히 입력해 주세요.');
            return;
        }

        if (!userInfo) {
            customToast.error('유저 조회에 실패 했습니다. 문제가 지속적으로 발생시 새로고침 후 다시 시도해 주세요.');
            return;
        }

        const body = {
            userId: userInfo?.id,
            phoneNumber: phoneNumber,
            verificationCode: verificationCode
        }

        CustomBackdropController().showBackdrop();
        const changePhoneNumberResult = await apiHook.reqChangePhoneNumber({ body: body })

        if (changePhoneNumberResult?.content) {
            onSetUserInfo(changePhoneNumberResult?.content);
            toggleOpenClose(false);
            customToast.success('전화번호가 정상적으로 등록되었습니다.');
        }

        CustomBackdropController().hideBackdrop();
    }

    const disableSendVerificationCodeButton = !CustomRegexUtils().checkPhoneNumberFormat(phoneNumber);
    
    return (
        <>
            <CustomDialog
                open={open}
            >
                {(!mode || mode === 'DEFAULT') &&
                    <>
                        <CustomDialog.Header style={{ padding: '20px 20px 0 20px' }}>
                            <CustomDialog.Header.Fake />
                            <CustomDialog.Header.Title>휴대전화 등록 및 변경</CustomDialog.Header.Title>
                            <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                        </CustomDialog.Header>
                        <St.RegistrationModal.Body>
                            <CustomLabelInput>
                                <CustomLabelInput.Input
                                    type='text'
                                    value={phoneNumber || ''}
                                    onChange={(e) => handleChangePhoneNumber(e)}
                                    inputMode='decimal'
                                    disabled={isLoading}
                                />
                                <CustomLabelInput.Label>"-" 를 제외한 휴대전화번호 <span style={{ color: 'var(--redColor1)' }}>*</span></CustomLabelInput.Label>
                            </CustomLabelInput>
                        </St.RegistrationModal.Body>
                        <St.RegistrationModal.Footer>
                            <CustomBlockButton
                                type='button'
                                onClick={() => handleSendVerificationCode()}
                                disabled={disableSendVerificationCodeButton || isLoading}
                            >
                                {isLoading ?
                                    <CircularProgress size={20} style={{ color: '#ffffff' }} />
                                    :
                                    <>인증번호 발송</>
                                }
                            </CustomBlockButton>
                        </St.RegistrationModal.Footer>
                    </>
                }
                {(mode === 'VERIFICATION') &&
                    <>
                        <CustomDialog.Header style={{ padding: '20px 20px 0 20px' }}>
                            <CustomDialog.Header.Back onClick={() => setMode('DEFAULT')} />
                            <CustomDialog.Header.Title>휴대전화 등록 및 변경</CustomDialog.Header.Title>
                            <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                        </CustomDialog.Header>
                        <St.RegistrationModal.Body>
                            <CustomLabelInput>
                                <CustomLabelInput.Input
                                    type='text'
                                    maxLength={6}
                                    value={verificationCode || ''}
                                    onChange={(e) => handleChangeVerificationCode(e)}
                                    inputMode='decimal'
                                />
                                <CustomLabelInput.Label>인증번호 <span style={{ color: 'var(--redColor1)' }}>*</span></CustomLabelInput.Label>
                            </CustomLabelInput>
                        </St.RegistrationModal.Body>
                        <St.RegistrationModal.Footer>
                            <CustomBlockButton
                                type='button'
                                onClick={() => handleRegisterPhoneNumber()}
                                disabled={verificationCode?.length < 6}
                            >등록</CustomBlockButton>
                        </St.RegistrationModal.Footer>
                    </>
                }
            </CustomDialog>
        </>
    );
}