import { GoogleAuthProvider, sendEmailVerification, signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';
import { CustomInput } from '../../components/input/CustomInput';
import * as St from './index.styled';
import firebaseAuth from '../../firebase/auth';
import { useUserContextActionsHook, useUserContextValueHook } from '../../contexts/UserContextProvider';
import { Link, useNavigate } from 'react-router-dom';
import { CustomRatioImage } from '../../components/image/CustomRatioImage';
import CustomBlockButton from '../../components/button/CustomBlockButton';
import { useState } from 'react';
import { CustomBackdropController } from '../../components/backdrop/default/v1/core';
import { customToast } from '../../components/toast/custom-react-toastify/v1';
import { useApiHook } from './hooks/useApiHook';

const customBackdropController = CustomBackdropController();

export function MainView(props) {
    const navigate = useNavigate();

    const apiHook = useApiHook();

    const userContextValueHook = useUserContextValueHook();
    const userContextActionsHook = useUserContextActionsHook();

    const userUid = userContextValueHook?.user?.uid;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [reSendEmailVerificationModeOpen, setReSendEmailVerificationModeOpen] = useState(false);

    const handleChangeEmailFromEvent = (e) => {
        let value = e.target.value;

        setEmail(value);
    }

    const handleChangePasswordFromEvent = (e) => {
        let value = e.target.value;

        setPassword(value);
    }

    const handleLoginWithGoogle = async () => {
        const provider = new GoogleAuthProvider();

        customBackdropController.showBackdrop();
        await signInWithPopup(firebaseAuth, provider)
            .then(data => {
                apiHook.reqUserSync({ body: null, headers: null });
                navigate('/', { replace: true });
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                customBackdropController.hideBackdrop();
            })
    }

    const handleLoginWithEmailAndPassword = async (e) => {
        e.preventDefault();
        if (!email) {
            customToast.error('이메일을 입력해 주세요.');
            return;
        }

        if (!password) {
            customToast.error('패스워드를 입력해 주세요.');
            return;
        }

        customBackdropController.showBackdrop();
        await signInWithEmailAndPassword(firebaseAuth, email, password)
            .then(data => {
                if (data?.user?.emailVerified) {
                    apiHook.reqUserSync({ body: null, headers: null });
                    navigate('/', { replace: true });
                } else {
                    setReSendEmailVerificationModeOpen(true);
                    customToast.error(`이메일 인증을 완료해 주세요.`);
                    return;
                }
            })
            .catch(err => {
                customToast.error('아이디 또는 패스워드가 틀립니다.');
                return;
            })
        customBackdropController.hideBackdrop();
    }

    const handleReSendEmailVerification = async () => {
        customBackdropController.showBackdrop();
        await sendEmailVerification(firebaseAuth.currentUser)
            .then(res => {
                customToast.success(`인증 메일이 전송되었습니다.`);
            });
        customBackdropController.hideBackdrop();
    }

    const handleSignOut = () => {
        signOut(firebaseAuth);
    }

    return (
        <>
            <St.Container>
                <form onSubmit={(e) => handleLoginWithEmailAndPassword(e)}>
                    <St.Wrapper>
                        <St.LogoWrapper>
                            <Link to='/'>
                                <CustomRatioImage width={1} height={1}>
                                    <CustomRatioImage.Image src={'/assets/images/shooluk_logo.png'} />
                                </CustomRatioImage>
                            </Link>
                        </St.LogoWrapper>
                        <St.IntroduceWrapper>
                            <div className='mainDescription'>슈룩에 오신것을 환영합니다.</div>
                            <div className='subDescription'>여러가지 링크를 하나의 링크로 관리해 보세요.</div>
                        </St.IntroduceWrapper>
                        {!userUid &&
                            <>
                                <St.InputGroupWrapper>
                                    <CustomInput
                                        type='email'
                                        value={email || ''}
                                        onChange={(e) => handleChangeEmailFromEvent(e)}
                                        placeholder='이메일'
                                    />
                                    <CustomInput
                                        type='password'
                                        value={password || ''}
                                        onChange={(e) => handleChangePasswordFromEvent(e)}
                                        placeholder='비밀번호'
                                    />
                                    <div className='forgotWrapper'>
                                        <Link
                                            to='/find/user'
                                        >
                                            아이디 찾기
                                        </Link>
                                        <div>•</div>
                                        <Link
                                            to='/find/password'
                                        >비밀번호 찾기</Link>
                                    </div>
                                </St.InputGroupWrapper>
                                <St.LoginButtonGroup>
                                    <CustomBlockButton
                                        type='submit'
                                        className='login'
                                    >
                                        이메일 로그인
                                    </CustomBlockButton>
                                    {reSendEmailVerificationModeOpen &&
                                        <CustomBlockButton
                                            type='button'
                                            className='reSendEmailVerification'
                                            onClick={() => handleReSendEmailVerification()}
                                        >
                                            인증 메일 재전송
                                        </CustomBlockButton>
                                    }
                                </St.LoginButtonGroup>
                                <St.LineBreaker>or</St.LineBreaker>
                                <St.SnsLoginButtonGroup>
                                    <CustomBlockButton type='button' onClick={() => handleLoginWithGoogle()}>
                                        <div className='content'>
                                            <div className='logoIcon'>
                                                <CustomRatioImage width={1} height={1}>
                                                    <CustomRatioImage.Image src={'/assets/icons/google_icon.svg'} />
                                                </CustomRatioImage>
                                            </div>
                                            <div>Google 계정으로 로그인</div>
                                        </div>
                                    </CustomBlockButton>
                                </St.SnsLoginButtonGroup>
                                <St.FooterFieldWrapper>
                                    <div className='text'>아직 슈룩의 회원이 아니신가요?</div>
                                    <div>
                                        <Link
                                            to='/signup'
                                        >
                                            <CustomBlockButton
                                                type='button'
                                                className='button'
                                            >
                                                무료 회원가입
                                            </CustomBlockButton>
                                        </Link>
                                    </div>
                                </St.FooterFieldWrapper>
                            </>
                        }

                        {userUid &&
                            <>
                                <St.LoginButtonGroup>
                                    <CustomBlockButton type='button' onClick={() => handleSignOut()}>로그아웃</CustomBlockButton>
                                </St.LoginButtonGroup>

                            </>
                        }
                    </St.Wrapper>
                </form>
            </St.Container>
        </>
    );
}