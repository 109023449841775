import { useEffect, useState } from "react";
import { useUserContextValueHook } from "../../../contexts/UserContextProvider";
import { TopNavBar } from "../../../layouts/TopNavBar/TopNavBar";
import { WegetAnonymous } from "./wegets/weget-anonymous/weget-anonymous";
import { WegetLoginMethods } from "./wegets/weget-login-methods/weget-login-methods";
import { WegetLogoutButton } from "./wegets/weget-logout-button/weget-logout-button";
import { WegetProfileBadge } from "./wegets/weget-profile-badge/weget-profile-badge";
import { useApiHook } from "./hooks/useApiHook";
import { WegetPasswordChange } from "./wegets/weget-password-change/weget-password-change";
import { WegetAccountDeletion } from "./wegets/weget-account-deletion/weget-account-deletion";
import { WegetPhoneSetting } from "./wegets/weget-phone-setting/weget-phone-setting";

export function MainView(props) {
    const userContextValueHook = useUserContextValueHook();

    const apiHook = useApiHook();

    return (
        <>
            <TopNavBar />
            {(userContextValueHook?.isLoading === false && !userContextValueHook?.user) ?
                <>
                    <WegetAnonymous />
                </>
                :
                <>
                    <WegetProfileBadge />
                    <WegetLogoutButton />
                    <WegetLoginMethods />
                    <WegetPhoneSetting />
                    <WegetPasswordChange />
                    <WegetAccountDeletion />
                </>
            }
        </>
    );
}