import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import UserPage from './pages/[userPageId]/index';
import MyPage from './pages/[userPageId]/mypage';
import PreviewPage from './pages/[userPageId]/preview';
import CreateMyPage from './pages/create/mypage';
import SignupPage from './pages/signup';
import ProfilePage from './pages/profile';
import FindUserPage from './pages/find/user';
import FindPasswordPage from './pages/find/password';
import { useUserContextValueHook } from './contexts/UserContextProvider';
import { PageLoading } from './components/loading/PageLoading/PageLoading';
import { CustomDefaultBackdrop } from './components/backdrop/default/v1/components/CustomDefaultBackdrop';
import { UserContextFetcher } from './fetchers/UserContextFetcher';
import { UserPageContextFetcher } from './fetchers/UserPageContextFetcher';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <>
      <HelmetProvider>
        <UserContextFetcher />
        <UserPageContextFetcher />

        <CustomDefaultBackdrop />

        <BrowserRouter>
          <AppCore />
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

function AppCore() {
  const userContextValueHook = useUserContextValueHook();

  if (userContextValueHook?.isLoading) {
    return <PageLoading />;
  }

  return (
    <>
      <Helmet>
        <title>슈룩 - 멀티 프로필링크</title>
        <meta name="description" content="무료 멀티링크 연결 서비스. 인스타, 유튜브, 쇼핑몰, 포트폴리오 등 다양한 링크를 정리하고 싶을때, 프로필링크를 사용해보세요." />
        <meta name="keywords" content="인스타프로필링크, 멀티링크, 인스타그램프로필링크, 유튜브프로필링크, 틱톡프로필링크, 트위터프로필링크, 카카오톡프로필링크" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="슈룩 - 멀티 프로필링크" />
        <meta property="og:description" content="무료 멀티링크 연결 서비스. 인스타, 유튜브, 쇼핑몰, 포트폴리오 등 다양한 링크를 정리하고 싶을때, 프로필링크를 사용해보세요." />
        <meta property="og:url" content="https://shool.uk" />
        <meta property="og:image" content="https://shool.uk/assets/images/shooluk_logo.png" />
        <meta property="og:image:secure_url" content="https://shool.uk/assets/images/shooluk_logo.png" />
        <meta property="og:site_name" content="슈룩 - 멀티 프로필링크" />
      </Helmet>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/signup' element={<SignupPage />} />
        <Route path='/profile' element={<ProfilePage />} />
        <Route path='/create/mypage' element={<CreateMyPage />} />
        <Route path='/preview/:userPageId' element={<PreviewPage />} />
        <Route path='/find/user' element={<FindUserPage />} />
        <Route path='/find/password' element={<FindPasswordPage />} />
        <Route path='/:userPageId' element={<UserPage />} />
        <Route path='/:userPageId/mypage' element={<MyPage />} />
        {/* <Route path='/products' element={<ProductsPage />} /> */}
      </Routes>
    </>
  )
}

export default App;
