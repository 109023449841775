import { UsersApi } from "../../../../apis/UsersApi";
import { VerificationApi } from "../../../../apis/VerificationApi";
import { useErrorHandler } from "../../../../apis/useErrorHandler";
import { tokenRefreshBeforeRequest } from "../../../../firebase/auth";


const usersApi = UsersApi();
const verificationApi = VerificationApi();

export function useApiHook(props) {
    const errorHandler = useErrorHandler();

    const reqFetchUser = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await usersApi.getByUid({ body: body, headers: headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
    }

    const reqDeletionAccount = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await usersApi.deletionAccount({ body: body, headers: headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
    }

    const reqSendPhoneVerificationCode = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await verificationApi.sendPhoneVerificationCode({ body: body, headers: headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
    }

    const reqChangePhoneNumber = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await usersApi.changePhoneNumber({ body: body, headers: headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
    }

    return {
        reqFetchUser,
        reqDeletionAccount,
        reqSendPhoneVerificationCode,
        reqChangePhoneNumber
    }
}