import { useNavigate } from 'react-router-dom';
import CustomBlockButton from '../../../../../components/button/CustomBlockButton';
import * as St from './weget-logout-button.styled';
import { signOut } from 'firebase/auth';
import firebaseAuth from '../../../../../firebase/auth';

export function WegetLogoutButton(props) {
    const navigate = useNavigate();
    const handleSignOut = async () => {
        const signoutResult = await signOut(firebaseAuth);
        navigate({
            pathname: '/'
        })
    }

    return (
        <>
            <St.Container>
                <CustomBlockButton
                    type='button'
                    onClick={() => handleSignOut()}
                >
                    로그아웃
                </CustomBlockButton>
            </St.Container>
        </>
    );
}