import styled from 'styled-components';

export const Container = styled.div`
    max-width: 600px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    padding: 0 10px;
`;

export const ContentBox = styled.div`
    padding: 20px;
    border: none;
    border-radius: 10px;
    border: 1px solid #e0e0e0;

    .title{
        margin: 0;
        font-size: 1.2em;
    }

    .sendMailBox{
        margin-top: 20px;

        button{
            max-width: 300px;
            border-radius: 10px;
            background: #000000;
            border: none;
            color: #ffffff;
            font-weight: 700;
        }
    }
`;