import styled from 'styled-components';

export const ProfileBadgeContainer = styled.div`
    margin-top: 20px;
    padding: 10px;

    .badge{
        width: 100px;
        margin-left: auto;
        margin-right: auto;
    }

    .account{
        margin-top: 10px;
        display: flex;
        justify-content: center;
        
        .flex{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            .email{
                flex:1;
                word-break: break-all;
                font-weight: 700;
                font-size: 14px;
            }
    
            .validationIcon{
                width: 20px;
                height: 20px;
            }
        }
    }

    .dateBox{
        margin-top: 10px;
        text-align: center;
        font-size: 13px;
        color: #666;
    }
`;