import axios from "axios"

const mainApiHost = process.env.REACT_APP_MAIN_API_HOST;

export function UsersApi(props) {
    return {
        getByUid: async ({ body, headers }) => {
            return await axios.post(`${mainApiHost}/users/getByUid`, body, {
                headers: headers,
            });
        },
        sync: async ({ body, headers }) => {
            return await axios.post(`${mainApiHost}/users/sync`, body, {
                headers: headers
            });
        },
        deletionAccount: async ({ body, headers }) => {
            return await axios.post(`${mainApiHost}/users/deletionAccount`, body, {
                headers: headers
            });
        },
        changePhoneNumber: async ({ body, headers }) => {
            return await axios.patch(`${mainApiHost}/users/change/phoneNumber`, body, {
                headers: headers,
                withCredentials: true
            });
        },
        sendPhoneVerificationCodeForFindUser: async ({ body, headers }) => {
            return await axios.post(`${mainApiHost}/users/find-user/send-phone-verification-code`, body, {
                headers: headers,
                withCredentials: true
            });
        },
        findUser: async ({ body, headers }) => {
            return await axios.post(`${mainApiHost}/users/find-user`, body, {
                headers: headers,
                withCredentials: true
            });
        }
    }
}