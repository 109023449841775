import CustomBlockButton from '../../../../../components/button/CustomBlockButton';
import * as St from './weget-password-change.styled';
import firebaseAuth from '../../../../../firebase/auth';
import { deleteUser, sendPasswordResetEmail } from 'firebase/auth';
import { customToast } from '../../../../../components/toast/custom-react-toastify/v1';
import { useUserContextValueHook } from '../../../../../contexts/UserContextProvider';
import { CustomBackdropController } from '../../../../../components/backdrop/default/v1/core';

export function WegetPasswordChange(props) {
    const userContextValueHook = useUserContextValueHook();

    const providerData = userContextValueHook?.user?.providerData;

    const handleSendPasswordResetEmail = async () => {
        try {
            CustomBackdropController().showBackdrop();
            await sendPasswordResetEmail(firebaseAuth, userContextValueHook?.user?.email);
            customToast.success('패스워드 변경 메일이 전송되었습니다. 변경이 완료되면 다시 로그인을 해주시기 바랍니다.');
        } catch (err) {
            customToast.error('이메일 전송에 실패했습니다.');
        } finally {
            CustomBackdropController().hideBackdrop();
        }
    }

    if(!providerData?.find(r=>r.providerId === 'password')){
        return <></>
    }

    return (
        <>
            <St.Container>
                <St.ContentBox>
                    <h2 className='title'>이메일 로그인 패스워드 변경</h2>
                    <div className='sendMailBox'>
                        <CustomBlockButton
                            type='button'
                            onClick={() => handleSendPasswordResetEmail()}
                        >
                            변경 메일 발송
                        </CustomBlockButton>
                    </div>
                </St.ContentBox>
            </St.Container>
        </>
    );
}