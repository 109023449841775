import { Link } from 'react-router-dom';
import CustomBlockButton from '../../../../../components/button/CustomBlockButton';
import * as St from './weget-anonymous.styled';

export function WegetAnonymous(props) {
    return (
        <>
            <St.Container>
                <St.RequiredLogin>
                    로그인이 필요한 페이지 입니다.
                </St.RequiredLogin>
                <St.GotoLogin>
                    <Link to='/login'>
                        <CustomBlockButton
                            type='button'
                        >
                            로그인 바로가기
                        </CustomBlockButton>
                    </Link>
                </St.GotoLogin>
            </St.Container>
        </>
    );
}