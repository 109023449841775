import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 40px;
    max-width: 568px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 100px;
`;

export const Wrapper = styled.div`
    padding: 20px;
    border-radius: 15px;
    display: flex;
    gap: 40px;
    flex-direction: column;
`;

export const LogoWrapper = styled.div`
    width: 100px;
    margin-left: auto;
    margin-right: auto;
`;

export const IntroduceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap:10px;
    .mainDescription{
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        color: #000;
    }

    .subDescription{
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        color: #404040;
    }
`;

export const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    .sendVerificationCode{
        border-radius: 15px;
        background: #000;
        color: #fff;
        font-weight: 700;
        border: none;
    }

    .confirmButton{
        border-radius: 15px;
        background: var(--mainColor);
        color: #fff;
        font-weight: 700;
        border: none;
    }

    .retryButton{
        border-radius: 15px;
        background: #efefef;
        color: #000;
        font-weight: 500;
        border: none;
    }

    .forgotWrapper{
        display: flex;
        gap: 5px;
        font-size: 13px;

        > a{
            text-decoration: underline;
            color: #666;

            &:hover{
                color: #444;
            }
        }
    }
`;

export const ResultBodyWrapper = styled.div`
    .title{
        font-weight: 600;
        color: var(--mainColor);
    }

    .resultBox{
        border: 1px solid #e0e0e0;
        padding: 20px;
        border-radius: 15px;
        margin-top: 20px;

        .account{
            font-size: 18px;
            font-weight: 600;
        }

        .relatedLogins{
            margin-top: 10px;
            .title{
                color: #000;
                font-size: 14px;
            }

            .iconList{
                margin-top: 10px;
                display: flex;
                gap: 10px;
                .icon{
                    border: 1px solid #e0e0e0;
                    border-radius: 30px;
                    padding: 5px;
                    width: 35px;
                    height: 35px;
                    box-sizing: border-box;
                }
            }
        }
    }

    .footerBox{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        button{
            border-radius: 15px;
            font-weight: 700;
            border: 1px solid #000;
            color: #000;
        }
    }
`;