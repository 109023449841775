import styled from 'styled-components';

export const Container = styled.div`
    max-width: 600px;
    padding: 0 10px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    
    button{
        border-radius: 30px;
        border: none;
        background-color: #000000;
        color: #ffffff;
        font-weight: 700;
    }
`;