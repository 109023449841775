import { Tooltip } from '@mui/material';
import { CustomRatioImage } from '../../../../../components/image/CustomRatioImage';
import { useUserContextValueHook } from '../../../../../contexts/UserContextProvider';
import * as St from './weget-profile-badge.styled';
import { CustomDateTimeUtils } from '../../../../../utils/CustomDateTimeUtils';

export function WegetProfileBadge(props) {
    const userContextValueHook = useUserContextValueHook();

    return (
        <>
            <St.ProfileBadgeContainer>
                <div className='badge'>
                    <CustomRatioImage>
                        <CustomRatioImage.Image src='/assets/icons/profile_circle_000000.svg' />
                    </CustomRatioImage>
                </div>
                <div className='account'>
                    <div className='flex'>
                        <div className='email'>
                            계정 : {userContextValueHook?.user?.email}
                        </div>
                        <Tooltip title='이메일 인증됨'>
                            <div className='validationIcon'>
                                <CustomRatioImage>
                                    <CustomRatioImage.Image src='/assets/icons/validation_star_green.svg' />
                                </CustomRatioImage>
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <div className='dateBox'>
                    <div>
                        마지막 로그인 : {CustomDateTimeUtils().convertDateTimeToYYYYMMDDhhmmss(CustomDateTimeUtils().conventTimestampToLocalDateTime(userContextValueHook?.user?.lastLoginAt))}
                    </div>
                </div>
            </St.ProfileBadgeContainer>
        </>
    );
}