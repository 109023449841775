import styled from 'styled-components';

export const Container = styled.div`
    max-width: 600px;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    padding: 0 10px;
`;

export const ContentBox = styled.div`
    padding: 20px;
    border: none;
    border-radius: 10px;
    border: 1px solid #e0e0e0;

    .title{
        margin: 0;
        font-size: 1.2em;
    }

    .signoutButtonBox{
        margin-top: 20px;

        button{
            max-width: 100px;
            height: 40px;
            border-radius: 10px;
            background: #ffffff;
            border: 1px solid var(--redColor1);
            color: var(--redColor1);
            font-weight: 700;
        }
    }
`;

export const DeletionModal = {
    Container: styled.div`
        
    `,
    Header: styled.div`
        padding: 20px;
    `,
    Description: styled.div`
        padding: 0 20px;
        .title{
            margin: 0;
        }

        .paragraph{
            margin-top: 10px;
            color: #404040;
            font-size: 14px;
            word-break: keep-all;
        }
    `,
    Reason: styled.div`
        margin-top: 20px;
        padding: 0 20px;
        .title{
            margin: 0;
        }

        .list{
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .item{
                cursor: pointer;
                border: 1px solid #e0e0e0;
                padding: 20px;
                border-radius: 10px;
                font-weight: 600;

            }

            .item.active{
                border-color: #000000;
            }

            .textareaBox{
                .counter{
                    margin-top: 5px;
                    text-align: right;
                    font-size: 10px;
                }
            }
        }
    `,
    Footer: styled.div`
        padding: 40px 20px;

        button{
            border-radius: 30px;
            background: var(--redColor1);
            border: none;
            color: #ffffff;
            font-weight: 700;
        }
    `,
}