import { useState } from 'react';
import CustomBlockButton from '../../../../../components/button/CustomBlockButton';
import * as St from './weget-account-deletion.styled';
import { CustomDialog } from '../../../../../components/dialog/v1/CustomDialog';
import { CustomLabelTextarea } from '../../../../../components/textarea/CustomLabelTextarea';
import { customToast } from '../../../../../components/toast/custom-react-toastify/v1';
import { useApiHook } from '../../hooks/useApiHook';
import { CustomBackdropController } from '../../../../../components/backdrop/default/v1/core';
import { useNavigate } from 'react-router-dom';

export function WegetAccountDeletion(props) {
    const [deletionModalOpen, setDeletionModalOpen] = useState(false);

    const toggleDeletionModalOpen = (bool) => {
        setDeletionModalOpen(bool);
    }

    return (
        <>
            <St.Container>
                <St.ContentBox>
                    <h2 className='title'>회원탈퇴</h2>
                    <div className='signoutButtonBox'>
                        <CustomBlockButton
                            type='button'
                            onClick={() => toggleDeletionModalOpen(true)}
                        >
                            회원탈퇴하기
                        </CustomBlockButton>
                    </div>
                </St.ContentBox>
            </St.Container>

            {deletionModalOpen &&
                <DeletionModal
                    open={deletionModalOpen}
                    toggleOpenClose={toggleDeletionModalOpen}
                />
            }
        </>
    );
}

function DeletionModal({
    open,
    toggleOpenClose,
}) {
    const navigate = useNavigate();
    const apiHook = useApiHook();

    const [deletionConfig, setDeletionConfig] = useState({
        reason: '',
        reasonDetail: ''
    });

    const handleChangeDeletionConfig = (reason) => {
        const deletionResaon = DELETION_REASONS?.find(r => r.reason === reason);
        setDeletionConfig({
            reason: deletionResaon.reason,
            reasonDetail: deletionResaon.reasonDetail
        })
    }

    const handleChangeReasonDetail = (e) => {
        const value = e.target.value;

        setDeletionConfig(prev => {
            return {
                ...prev,
                reasonDetail: value
            }
        })
    }

    const handleSubmit = async () => {
        if (deletionConfig?.reasonDetail?.length < 5 || deletionConfig?.reasonDetail?.length > 300) {
            customToast.error('기타이유는 5 ~ 300자 이내로 작성해 주세요. ')
        }

        let body = {
            deletionConfig: deletionConfig,
        }
        CustomBackdropController().showBackdrop();
        const deletionResult = await apiHook.reqDeletionAccount({ body: body, headers: null });

        if (deletionResult?.content) {
            alert('회원탈퇴가 완료되었습니다.\n지금까지 이용해주셔서 감사합니다.\n앞으로 더 나은 서비스를 위해 노력하겠습니다.')

            window.location.href = '/';
        }
        CustomBackdropController().hideBackdrop();
    }

    return (
        <CustomDialog
            open={open}
            onClose={() => toggleOpenClose(false)}
        >
            <St.DeletionModal.Container>
                <St.DeletionModal.Header>
                    <CustomDialog.Header>
                        <CustomDialog.Header.Fake />
                        <CustomDialog.Header.Title>
                            회원탈퇴
                        </CustomDialog.Header.Title>
                        <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                    </CustomDialog.Header>
                </St.DeletionModal.Header>
                <St.DeletionModal.Description>
                    <h3 className='title'>회원님과 정말 이별인가요? 너무 아쉬워요.</h3>
                    <div className='paragraph'>
                        계정을 탈퇴하면 생성된 페이지 등 모든 활동 정보가 삭제됩니다.<br />삭제된 데이터는 복구가 불가능 합니다.<br />계정 탈퇴 후 7일간 다시 가입할 수 없어요.
                    </div>
                </St.DeletionModal.Description>
                <St.DeletionModal.Reason>
                    <h3 className='title'>회원님의 탈퇴 이유가 궁금해요.</h3>
                    <div className='list'>
                        {DELETION_REASONS?.map(deletionReason => {
                            return (
                                <div
                                    key={deletionReason.reason}
                                    className={`item ${deletionReason.reason === deletionConfig?.reason ? 'active' : ''}`}
                                    onClick={() => handleChangeDeletionConfig(deletionReason.reason)}
                                >
                                    {deletionReason?.reason}
                                </div>
                            );
                        })}
                        {deletionConfig?.reason === '기타' &&
                            <div className='textareaBox'>
                                <CustomLabelTextarea
                                    labelElement={<>기타이유</>}
                                    value={deletionConfig?.reasonDetail || ''}
                                    onChange={(e) => handleChangeReasonDetail(e)}
                                    maxLength={300}
                                    placeholder={'서비스 탈퇴 사유에 대해 알려주세요.\n회원님의 소중한 피드백을 담아\n더 나은 서비스로 보답 드리도록 하겠습니다.'}
                                    style={{ height: '100px' }}
                                />
                                <div className='counter'>{deletionConfig?.reasonDetail?.length} / 300</div>
                            </div>
                        }
                    </div>
                </St.DeletionModal.Reason>
                <St.DeletionModal.Footer>
                    <CustomBlockButton
                        type='button'
                        onClick={() => handleSubmit()}
                        disabled={!deletionConfig?.reason || deletionConfig?.reasonDetail?.length < 5 || deletionConfig?.reasonDetail?.length > 300}
                    >
                        탈퇴하기
                    </CustomBlockButton>
                </St.DeletionModal.Footer>
            </St.DeletionModal.Container>
        </CustomDialog>
    );
}

const DELETION_REASONS = [
    {
        id: 1,
        reason: '너무 어려워요',
        reasonDetail: '너무 어려워요'
    },
    {
        id: 2,
        reason: '서비스가 필요가 없어졌어요',
        reasonDetail: '서비스가 필요가 없어졌어요'
    },
    {
        id: 3,
        reason: '새 계정을 만들고 싶어요',
        reasonDetail: '새 계정을 만들고 싶어요'
    },
    {
        id: 4,
        reason: '기타',
        reasonDetail: ''
    },
]