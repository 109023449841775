import axios from "axios"

const mainApiHost = process.env.REACT_APP_MAIN_API_HOST;

export function VerificationApi(props) {
    return {
        sendPhoneVerificationCode: async ({ body, headers }) => {
            return await axios.post(`${mainApiHost}/verification/sms/send`, body, {
                headers: headers,
                withCredentials: true
            });
        },
    }
}