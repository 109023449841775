import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 40px;
    max-width: 568px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 100px;
`;

export const Wrapper = styled.div`
    padding: 20px;
    border-radius: 15px;
    display: flex;
    gap: 40px;
    flex-direction: column;
`;

export const LogoWrapper = styled.div`
    width: 100px;
    margin-left: auto;
    margin-right: auto;
`;

export const IntroduceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap:10px;
    .mainDescription{
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        color: #000;
    }

    .subDescription{
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        color: #404040;
    }
`;

export const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    .confirmButton{
        border-radius: 15px;
        background: #000;
        color: #fff;
        font-weight: 700;
        border: none;
    }

    .resultBox{
        border: 1px solid #e0e0e0;
        border-radius: 15px;
        padding: 20px;
        text-align: center;

        > .title{
            font-size: 18px;
            font-weight: 600;
        }

        > .description{
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 20px;
            font-size: 14px;
            word-break: keep-all;
        }
    }

    .forgotWrapper{
        display: flex;
        gap: 5px;
        font-size: 13px;

        > a{
            text-decoration: underline;
            color: #666;

            &:hover{
                color: #444;
            }
        }
    }
    
    .footerBox{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        button{
            border-radius: 15px;
            font-weight: 700;
            border: 1px solid #000;
            color: #000;
        }
    }
`;