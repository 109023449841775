import styled from 'styled-components';

export const Container = styled.div`
    max-width: 600px;
    padding: 0 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
`;

export const RequiredLogin = styled.div`
    font-size: 20px;
    font-weight: 600;
    text-align: center;
`;

export const GotoLogin = styled.div`
    margin-top: 40px;

    button{
        border-radius: 30px;
        border: none;
        background-color: #000;
        color: #fff;
        font-weight: 700;
    }
`;