import { Link } from 'react-router-dom';
import * as St from './index.styled';
import { CustomRatioImage } from '../../../components/image/CustomRatioImage';
import { CustomLabelInput } from '../../../components/input/CustomLabelInput';
import CustomBlockButton from '../../../components/button/CustomBlockButton';
import { useState } from 'react';
import { CustomRegexUtils } from '../../../utils/CustomRegexUtils';
import { customToast } from '../../../components/toast/custom-react-toastify/v1';
import { useApiHook } from './hooks/useApiHook';
import { Tooltip } from '@mui/material';
import { CustomBackdropController } from '../../../components/backdrop/default/v1/core';

export function MainView(props) {
    const apiHook = useApiHook();

    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerficationCode] = useState('');
    const [mode, setMode] = useState('DEFAULT') // DEFAULT, VERIFICATION, RESULT

    const [relatedUsers, setRelatedUsers] = useState(null);

    const handleChangePhoneNumber = (e) => {
        let value = e.target.value;

        const regex = /^[0-9]{0,11}$/;

        if (regex.test(value)) {
            setPhoneNumber(value);
        }
    }

    const handleChangeVerificationCode = (e) => {
        let value = e.target.value;

        const regex = /^[0-9]{0,6}$/;

        if (regex.test(value)) {
            setVerficationCode(value);
        }
    }

    const handleSendVerificationCode = async () => {
        if (!CustomRegexUtils().checkPhoneNumberFormat(phoneNumber)) {
            customToast.error('휴대전화번호를 정확히 입력해 주세요.');
            return;
        }

        const body = {
            phoneNumber: phoneNumber
        }

        await apiHook.reqSendPhoneVerificationCode({ body });
        setMode('VERIFICATION');
    }

    const handleReqFindUser = async () => {
        const body = {
            phoneNumber: phoneNumber,
            verificationCode: verificationCode
        }

        CustomBackdropController().showBackdrop();
        const findUserResult = await apiHook.reqFindUser({ body });
        if (findUserResult?.content) {
            setRelatedUsers(findUserResult?.content);
            setMode('RESULT');
        }
        CustomBackdropController().hideBackdrop();
    }

    const handleClickRetry = () => {
        setMode('DEFAULT');
    }

    return (
        <>
            <St.Container>
                <St.Wrapper>
                    <St.LogoWrapper>
                        <Link to='/'>
                            <CustomRatioImage width={1} height={1}>
                                <CustomRatioImage.Image src={'/assets/images/shooluk_logo.png'} />
                            </CustomRatioImage>
                        </Link>
                    </St.LogoWrapper>
                    <St.IntroduceWrapper>
                        <div className='mainDescription'>슈룩 아이디 찾기</div>
                        <div className='subDescription'>계정에 등록된 휴대전화로 로그인 계정 찾을 수 있습니다.</div>
                    </St.IntroduceWrapper>
                    {['DEFAULT', 'VERIFICATION'].includes(mode) &&
                        <St.BodyWrapper>
                            {(!mode || mode === 'DEFAULT') &&
                                <>
                                    <CustomLabelInput>
                                        <CustomLabelInput.Input
                                            type='text'
                                            value={phoneNumber || ''}
                                            onChange={(e) => handleChangePhoneNumber(e)}
                                            disabled={mode !== 'DEFAULT'}
                                        />
                                        <CustomLabelInput.Label>휴대전화번호 <span style={{ color: 'var(--redColor1)' }}>*</span></CustomLabelInput.Label>
                                    </CustomLabelInput>
                                    <CustomBlockButton
                                        type='button'
                                        className='sendVerificationCode'
                                        onClick={() => handleSendVerificationCode()}
                                    >
                                        인증번호 발송
                                    </CustomBlockButton>
                                </>
                            }
                            {mode === 'VERIFICATION' &&
                                <>
                                    <div>
                                        <CustomLabelInput>
                                            <CustomLabelInput.Input
                                                type='text'
                                                value={verificationCode || ''}
                                                onChange={(e) => handleChangeVerificationCode(e)}
                                            />
                                            <CustomLabelInput.Label>인증번호 <span style={{ color: 'var(--redColor1)' }}>*</span></CustomLabelInput.Label>
                                        </CustomLabelInput>
                                        <div style={{ color: 'var(--redColor1)', fontSize: '12px', marginTop: '10px' }}>계정에 등록된 휴대전화가 없으면 인증번호를 받지 못합니다.</div>
                                    </div>
                                    <CustomBlockButton
                                        type='button'
                                        className='confirmButton'
                                        onClick={() => handleReqFindUser()}
                                        disabled={verificationCode?.length < 6}
                                    >
                                        확인
                                    </CustomBlockButton>
                                    <CustomBlockButton
                                        type='button'
                                        className='retryButton'
                                        onClick={() => handleClickRetry()}
                                    >
                                        재시도
                                    </CustomBlockButton>
                                </>
                            }
                            <div className='forgotWrapper'>
                                <Link
                                    to='/find/password'
                                >비밀번호 찾기</Link>
                                <div>•</div>
                                <Link
                                    to='/login'
                                >로그인 페이지로 이동</Link>
                            </div>
                        </St.BodyWrapper>
                    }
                    {(mode === 'RESULT' && relatedUsers) &&

                        <St.ResultBodyWrapper>
                            <div className='title'>
                                "{phoneNumber}"에 연동된 {relatedUsers && relatedUsers?.length}개의 계정을 찾았습니다.
                            </div>
                            {relatedUsers && relatedUsers?.map(relatedUser => {
                                return (
                                    <div className='resultBox' key={relatedUser?.email}>
                                        <div className='account'>
                                            {relatedUser?.email}
                                        </div>
                                        <div className='relatedLogins'>
                                            <div className='title'>
                                                연동된 로그인 방식
                                            </div>
                                            <div className='iconList'>
                                                {relatedUser?.providerIdList?.map(providerId => {
                                                    if (providerId === 'password') {
                                                        return (
                                                            <Tooltip title='이메일 로그인' key={providerId}>
                                                                <div className='icon'>
                                                                    <CustomRatioImage>
                                                                        <CustomRatioImage.Image src='/assets/icons/mail_default_000000.svg' />
                                                                    </CustomRatioImage>
                                                                </div>
                                                            </Tooltip>
                                                        );
                                                    }

                                                    if (providerId === 'google.com') {
                                                        return (
                                                            <Tooltip title='구글 로그인' key={providerId}>
                                                                <div className='icon'>
                                                                    <CustomRatioImage>
                                                                        <CustomRatioImage.Image src='/assets/icons/google_icon.svg' />
                                                                    </CustomRatioImage>
                                                                </div>
                                                            </Tooltip>
                                                        );
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <div className='footerBox'>
                                <Link to='/find/password'>
                                    <CustomBlockButton
                                        type='button'
                                    >비밀번호 찾기</CustomBlockButton>
                                </Link>
                                <Link to='/login'>
                                    <CustomBlockButton
                                        type='button'
                                    >로그인 페이지로 이동</CustomBlockButton>
                                </Link>
                            </div>
                        </St.ResultBodyWrapper>
                    }
                </St.Wrapper>
            </St.Container>
        </>
    );
}