import { Link } from 'react-router-dom';
import * as St from './index.styled';
import { CustomRatioImage } from '../../../components/image/CustomRatioImage';
import { CustomLabelInput } from '../../../components/input/CustomLabelInput';
import CustomBlockButton from '../../../components/button/CustomBlockButton';
import firebaseAuth from '../../../firebase/auth';
import { deleteUser, sendPasswordResetEmail } from 'firebase/auth';
import { CustomBackdropController } from '../../../components/backdrop/default/v1';
import { customToast } from '../../../components/toast/custom-react-toastify/v1';
import { useState } from 'react';

export function MainView(props) {
    const [email, setEmail] = useState('');
    const [mode, setMode] = useState('DEFAULT'); // DEFAULT, RESULT

    const handleChangeEmail = (e) => {
        const value = e.target.value;
        setEmail(value);
    }

    const handleSendPasswordResetEmail = async (e) => {
        e.preventDefault();
        try {
            CustomBackdropController().showBackdrop();
            await sendPasswordResetEmail(firebaseAuth, email);
            customToast.success('패스워드 변경 메일이 전송되었습니다.');
            setMode('RESULT');
        } catch (err) {
            customToast.error('이메일 전송에 실패했습니다.');
        } finally {
            CustomBackdropController().hideBackdrop();
        }
    }

    return (
        <>
            <St.Container>
                <St.Wrapper>
                    <St.LogoWrapper>
                        <Link to='/'>
                            <CustomRatioImage width={1} height={1}>
                                <CustomRatioImage.Image src={'/assets/images/shooluk_logo.png'} />
                            </CustomRatioImage>
                        </Link>
                    </St.LogoWrapper>
                    <St.IntroduceWrapper>
                        <div className='mainDescription'>슈룩 비밀번호 찾기</div>
                        <div className='subDescription'>등록된 계정의 이메일 주소를 통해 비밀번호를 수정해 주세요.</div>
                    </St.IntroduceWrapper>
                    {(!mode || mode === 'DEFAULT') &&
                        <form onSubmit={handleSendPasswordResetEmail}>
                            <St.BodyWrapper>
                                <CustomLabelInput>
                                    <CustomLabelInput.Input
                                        type='email'
                                        value={email || ''}
                                        onChange={(e) => handleChangeEmail(e)}
                                    />
                                    <CustomLabelInput.Label>
                                        이메일 <span style={{ color: 'var(--redColor1)' }}>*</span>
                                    </CustomLabelInput.Label>
                                </CustomLabelInput>
                                <CustomBlockButton
                                    type='submit'
                                    className='confirmButton'
                                >
                                    이메일 발송
                                </CustomBlockButton>
                                <div className='forgotWrapper'>
                                    <Link
                                        to='/find/user'
                                    >아이디 찾기</Link>
                                    <div>•</div>
                                    <Link
                                        to='/login'
                                    >로그인 페이지로 이동</Link>
                                </div>
                            </St.BodyWrapper>
                        </form>
                    }
                    {mode === 'RESULT' &&
                        <St.BodyWrapper>
                            <div className='resultBox'>
                                <div className='title'>패스워드 변경 메일이 발송되었습니다.</div>
                                <div className='description'>
                                    <div>슈룩에 등록된 계정이 아닐 경우 이메일을 받지 못합니다.</div>
                                    <div>인증번호가 오지 않으면 입력하신 정보가 정확한지 확인하여 주세요.</div>
                                </div>
                            </div>
                            <div className='footerBox'>
                                <div className='footerBox'>
                                    <Link to='/find/user'>
                                        <CustomBlockButton
                                            type='button'
                                        >아이디 찾기</CustomBlockButton>
                                    </Link>
                                    <Link to='/login'>
                                        <CustomBlockButton
                                            type='button'
                                        >로그인 페이지로 이동</CustomBlockButton>
                                    </Link>
                                </div>
                            </div>
                        </St.BodyWrapper>
                    }
                </St.Wrapper>
            </St.Container>
        </>
    );
}