import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    z-index: 100;
    background-color: #fff;
`;

export const Wrapper = styled.div`
    height: 80px;
    padding: 0 20px;
    border-bottom: 1px solid #e0e0e0;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ContentItemsWrapper = styled.div`
    .layout{
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
    }

    .layout__link{
        display: inline-block;
        padding: 10px 16px;
    }

    .layout__link.logo{
        width: 48px;
        padding: 0;
    }

    .layout__link.mypage{
        border: 1px solid #00000000;
        font-size: 16px;
        font-weight: 700;
        border-radius: 10px;
        
        &:hover{
            border: 1px solid #E5EAF2;
            background-color: #F3F6F9;
        }
    }

    .layout__link.default{
        border: 1px solid #00000000;
        font-size: 16px;
        font-weight: 700;
        border-radius: 10px;

        &:hover{
            border: 1px solid #E5EAF2;
            background-color: #F3F6F9;
        }
    }
`;

export const UserStatusWrapper = styled.div`
    .layout{
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
    }

    .layout__link{
        display: inline-block;
        padding: 10px 16px;
    }

    .layout__link.signIn{
        border: 1px solid #00000000;
        background: #000;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        border-radius: 10px;
        height: auto;

        &:hover{
            background-color: #303030;
        }
    }

    .layout__link.signUp{
        border: 1px solid #00000000;
        background: #000;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        border-radius: 10px;
        height: auto;

        &:hover{
            background-color: #303030;
        }
    }

    .layout__link.profileIcon{
        width: 30px;
        height: 30px;
        padding: 0;
        border:none;
        border-radius: 30px;
        transition: scale .3s;

        &:hover{
            scale: 1.1;
        }
    }

    .layout__link.logout{
        border: 1px solid #00000000;
        background: #000;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        border-radius: 10px;
        height: auto;

        &:hover{
            background-color: #303030;
        }
    }
`;