import styled from 'styled-components';

export const Container = styled.div`
    max-width: 600px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    padding: 0 10px;
`;

export const ContentBox = styled.div`
    padding: 20px;
    border: none;
    border-radius: 10px;
    border: 1px solid #e0e0e0;

    .title{
        margin: 0;
        font-size: 1.2em;
    }

    .iconList{
        margin-top: 20px;
        display: flex;
        gap: 10px;
        .icon{
            border: 1px solid #e0e0e0;
            border-radius: 30px;
            padding: 5px;
            width: 35px;
            height: 35px;
            box-sizing: border-box;
        }
    }
`;