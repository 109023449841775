import styled from 'styled-components';

export const Container = styled.div`
    max-width: 600px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    padding: 0 10px;
`;

export const ContentBox = styled.div`
    padding: 20px;
    border: none;
    border-radius: 10px;
    border: 1px solid #e0e0e0;

    .title{
        margin: 0;
        font-size: 1.2em;
    }

    .description{
        margin-top: 10px;
        font-size: 14px;
        font-weight: 600;
        color: var(--mainColor);
    }

    .phoneNumber{
        margin-top: 10px;
        font-size: 16px;
        font-weight: 600;
    }

    .sendMailBox{
        margin-top: 20px;

        button{
            max-width: 300px;
            border-radius: 10px;
            background: #000000;
            border: none;
            color: #ffffff;
            font-weight: 700;
        }
    }
`;

export const RegistrationModal = {
    Body: styled.div`
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    `,
    Footer:styled.div`
        padding: 0 20px 20px 20px;

        button{
            border-radius: 15px;
            background-color: var(--mainColor);
            color: #fff;
            font-weight: 700;
            border: none;
        }
    `
}